/**
 * Enum used to determin the color a component needs to use.
 */
export enum ColorTypesEnum {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    RACE_PRIMARY = 'race-primary',
    DEFAULT = 'default',
    WHITE = 'white',
    BLACK = 'black',
    WS_PRIMARY = 'ws-primary',
    WS_SECONDARY = 'ws-secondary',
    WS_VIRTUAL_CLUB = 'ws-virtual-club',
    VIRTUAL_CLUB = 'vc',
    GREY = 'grey',
    RED = 'red',
    GREEN = 'green',
}
