import { ReactElement } from 'react';
// Next
import { useTranslation } from 'next-i18next';
//Contextes
import { useGlobal } from '@utmb/contexts/GlobalContext';
// Types
import UserMeView from '@utmb/types/view/UserMeView';
// Design System
import { Font } from '@utmb/design-system/text/font';
import { Image } from '@utmb/design-system/image/Image';
import { Link } from '@utmb/design-system/link/link';
import { Placeholder } from '@utmb/design-system/placeholder/Placeholder';
// Utils
import { pIdToCloudinary } from '@utmb/utils/cloudinary';
// Styles
import styles from './user-information.module.scss';

interface UserInformationProps {
    user: UserMeView;
}

export const UserInformation = (props: UserInformationProps): ReactElement => {
    const {
        user: { profilePicture, fullname, profileUrl, anonymized },
    } = props;

    const { t } = useTranslation();
    const {
        global: {
            event: { isWS },
        },
    } = useGlobal();

    const profileLink = isWS && profileUrl ? new URL(profileUrl).pathname : profileUrl;

    // Convert the profilePicture to the Cloudinary format.
    const avatarCloudinary = pIdToCloudinary(profilePicture, fullname);

    return (
        <div className={styles.user_information}>
            <div className={styles.image_container}>
                {profilePicture ? (
                    <Image className={styles.image} image={avatarCloudinary} />
                ) : (
                    <Placeholder className={styles.placeholder} logoWidth={50} logoHeight={50} />
                )}
            </div>
            <div className={styles.content}>
                <Font.FuturaHeavy as="span" mobile="16" desktop="16">
                    {t('common.hi')} {fullname}
                </Font.FuturaHeavy>
                {profileLink && (
                    <Font.FuturaBook mobile="14" desktop="14" as="span">
                        {anonymized ? (
                            <a
                                href="#"
                                style={{ textDecoration: 'underline' }}
                                onClick={() => {
                                    alert(t('myAccount.anonymizedProfile'));
                                    return false;
                                }}
                            >
                                {t('myAccount.seeMyProfil')}
                            </a>
                        ) : (
                            <Link href={profileLink ?? '/'}>{t('myAccount.seeMyProfil')}</Link>
                        )}
                    </Font.FuturaBook>
                )}
            </div>
        </div>
    );
};
